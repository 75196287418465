<template>
  <div class="content_body SaleGeneralCardCommissionScheme" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="组织单位">
              <el-input
                @clear="handleSearch"
                v-model="Name"
                placeholder="输入组织单位名称搜索"
                clearable
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="tableData">
        <el-table-column label="组织单位" prop="EntityName"></el-table-column>

        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeEntityClick(scope.row)"
              v-prevent-click
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10">
        <div class="text_right" v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog
      title="新增通用次卡销售提成方案"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="组织单位" prop="EntityID">
            <span slot="label">
              适用组织
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需选择父节点。</p>
                <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                <p>
                  如需要设置经手人/职务提成，请选择已经配置经手人的组织单位。
                </p>
                <el-button
                  type="text"
                  style="color: #dcdfe6"
                  icon="el-icon-info"
                  slot="reference"
                ></el-button>
              </el-popover>
            </span>
            <treeselect
              v-model="ruleForm.EntityID"
              :options="entityList"
              :normalizer="normalizer"
              clearValueText
              noResultsText="无匹配数据"
              placeholder="选择所属部门"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClickAddRoyalty('ruleForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog :visible.sync="dialogEdit" width="70%">
      <div slot="title">{{ entityName }} - 通用次卡分类销售提成方案</div>
      <el-table
        size="small"
        :data="tableDataEdit"
        row-key="CategoryID"
        max-height="500px"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="CategoryName"
          label="通用次卡分类"
          min-width="150px"
          fixed
        ></el-table-column>
        <el-table-column label="现金比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.PayRate"
              v-enter-number
              class="input_type"
              type="number"
              size="mini"
              min="0"
              @input="royaltyRateChange(1, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="现金固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(2, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(3, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="特殊补贴" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SpecialBenefit"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handerCommission('0', scope.row)"
              v-if="scope.row.isEntity"
              >所有通用次卡经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="handerCommission('1', scope.row)"
              v-if="!scope.row.isEntity"
              >分类经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="clickProjectCommission(scope.row)"
              v-if="!scope.row.isEntity"
              >通用次卡提成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEdit = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--经手人弹窗-->
    <el-dialog :visible.sync="dialogHandler" width="60%">
      <div slot="title" v-if="whichHandlerDialog != '1'">
        {{ entityName }} - {{ categoryName }} - 经手人/职务销售提成方案
      </div>
      <div slot="title" v-else>
        {{ entityName }} - {{ categoryName }} - {{ productName }} -
        经手人/职务销售提成方案
      </div>
      <el-tabs v-model="handleActiveName" >
        <el-tab-pane label="通用次卡" name="0">
          <el-table
            size="small"
            :data="handlerList"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasHandlerJobTypeCommission',
            }"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  type="number"
                  min="0"
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="套餐卡 - 通用次卡" name="1">
          <el-table
            size="small"
            :data="packageHanderCommission"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasHandlerJobTypeCommission',
            }"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  type="number"
                  min="0"
                  class="input_type"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogHandler = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitHandlerClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--通用次卡提成设置-->
    <el-dialog :visible.sync="dialogGeneralCard" width="65%">
      <div slot="title">
        {{ entityName }} - {{ categoryName }} - 通用次卡销售提成方案
      </div>
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input
            v-model="SearchKey"
            placeholder="输入通用次卡名称搜索"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <el-table
        :data="
          generalCardList.filter(
            (data) =>
              !SearchKey ||
              data.GeneralCardName.toLowerCase().includes(
                SearchKey.toLowerCase()
              )
          )
        "
        row-key="GeneralCardID"
        size="small"
        max-height="500px"
      >
        <el-table-column
          prop="GeneralCardName"
          label="通用次卡名称"
          min-width="150px"
          fixed
        ></el-table-column>
        <el-table-column label="现金比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayRate"
              v-enter-number
              class="input_type"
              min="0"
              type="number"
              @input="royaltyRateChange(1, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="现金固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardRate"
          label="卡抵扣比例"
          min-width="105px"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(2, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="SavingCardFixed"
          label="卡抵扣固定"
          min-width="115px"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(3, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="特殊补贴" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SpecialBenefit"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="115px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handlerRoyalty(scope.row)"
              >经手人提成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogGeneralCard = false"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitGeneralCardClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/HanderCommission/saleGeneralCardCommissionScheme";
import permission from "@/components/js/permission.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
var Enumerable = require("linq");

export default {
  components: { Treeselect },
  name: "SaleGeneralCardCommissionScheme",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogEdit: false,
      dialogHandler: false,
      isDelete: false, // 删除按钮状态
      dialogGeneralCard: false,
      Name: "", // 搜索框数据
      entityList: [],
      tableData: [],
      tableDataEdit: [], // 编辑表格数据
      cardCategoryCommission: "",
      handlerList: [],
      packageHanderCommission: [],
      generalCardList: [],
      handlerType: "", // 当前经手人弹框类型
      entityName: "", // 组织名称
      EntityID: "", // 组织单位ID
      CategoryID: "", // 通用次卡分类ID
      SearchKey: "", // 分类下的通用次卡分类弹框搜索框数据
      GeneralCardID: "", // 通用次卡ID
      whichHandlerDialog: "", // 判断弹出框类别
      categoryName: "",
      productName: "",
      ruleForm: {
        EntityID: "",
      },
      rules: {
        EntityID: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      handleActiveName:"0",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-HanderCommission-SaleGeneralCardCommissionScheme-Delete"
      );
    });
  },


  methods: {
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.getSaleGeneralCardCommissionScheme();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getSaleGeneralCardCommissionScheme();
    },
    // 获取组织架构
    getEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
      };
      API.getEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡销售组织单位提成方案列表
    getSaleGeneralCardCommissionScheme: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.Name,
      };
      API.getSaleGeneralCardCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = Object.assign([], res.List);
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        entity: null,
      };
      that.dialogVisible = true;
    },
    // 提交新增通用次卡组织单位
    submitClickAddRoyalty(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.createSaleGeneralCardCommissionScheme();
        }
      });
    },
    // 创建通用次卡销售组织单位提成方案
    createSaleGeneralCardCommissionScheme: function () {
      var that = this;
      that.modalLoading = true;
      API.createSaleGeneralCardCommissionScheme(that.ruleForm)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getSaleGeneralCardCommissionScheme();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 删除按钮点击事件
    removeEntityClick: function (row) {
      var that = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.modalLoading = false;
          var params = {
            EntityID: row.EntityID,
          };
          API.deleteSaleGeneralCardCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("成功");
                that.getSaleGeneralCardCommissionScheme();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.EntityID = row.EntityID;
      that.entityName = row.EntityName;
      that.getSaleGeneralCardCategoryCommission();
      that.dialogEdit = true;
    },
    // 编辑信息提交
    submitClick: function () {
      var that = this;
      var Category = Object.assign([], that.tableDataEdit);
      var Entity = [];
      Category.forEach((item, index) => {
        if (item.isEntity) {
          Entity = item;
          Category.splice(index, 1);
        }
      });
      that.cardCategoryCommission.EntityID = Entity.EntityID;
      that.cardCategoryCommission.PayFixed = Entity.PayFixed;
      that.cardCategoryCommission.PayRate = Entity.PayRate;
      that.cardCategoryCommission.SavingCardFixed = Entity.SavingCardFixed;
      that.cardCategoryCommission.SavingCardRate = Entity.SavingCardRate;
      that.cardCategoryCommission.SavingCardLargessFixed =
        Entity.SavingCardLargessFixed;
      that.cardCategoryCommission.SavingCardLargessRate =
        Entity.SavingCardLargessRate;
      that.cardCategoryCommission.SpecialBenefit = Entity.SpecialBenefit;
      that.cardCategoryCommission.Category = Category;

      that.cardCategoryCommission.Category = Enumerable.from(
        that.cardCategoryCommission.Category
      )
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.updateSaleGeneralCardCategoryCommission();
    },
    // 保存通用次卡销售分类提成方案
    updateSaleGeneralCardCategoryCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.cardCategoryCommission;
      API.updateSaleGeneralCardCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogEdit = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 获取通用次卡销售分类提佣方案
    getSaleGeneralCardCategoryCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getSaleGeneralCardCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.cardCategoryCommission = res.Data;
            var data = {
              EntityID: res.Data.EntityID,
              EntityName: res.Data.EntityName,
              CategoryID: "0" + res.Data.EntityID,
              CategoryName: "所有通用次卡",
              categoryName: "所有通用次卡",
              PayFixed: res.Data.PayFixed,
              PayRate: res.Data.PayRate,
              SavingCardFixed: res.Data.SavingCardFixed,
              SavingCardLargessFixed: res.Data.SavingCardLargessFixed,
              SavingCardLargessRate: res.Data.SavingCardLargessRate,
              SavingCardRate: res.Data.SavingCardRate,
              SpecialBenefit: res.Data.SpecialBenefit,
              isEntity: true,
            };
            var Category = Enumerable.from(res.Data.Category)
              .select((val) => ({
                CategoryID: val.CategoryID,
                CategoryName: val.CategoryName,
                categoryName: val.CategoryName,
                PayFixed: val.PayFixed,
                PayRate: val.PayRate,
                SavingCardFixed: val.SavingCardFixed,
                SavingCardLargessFixed: val.SavingCardLargessFixed,
                SavingCardLargessRate: val.SavingCardLargessRate,
                SavingCardRate: val.SavingCardRate,
                SpecialBenefit: val.SpecialBenefit,
              }))
              .toArray();
            that.tableDataEdit = Object.assign([], Category);
            that.tableDataEdit.unshift(data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 通用次卡下经手人提佣点击事件
    handlerRoyalty(row) {
      var that = this;
      that.handleActiveName = "0"
      that.productName = row.GeneralCardName;
      that.GeneralCardID = row.GeneralCardID;
      that.dialogHandler = true;
      that.whichHandlerDialog = "1";
      that.getSaleGeneralCardHanderCommission();
      that.PackageSaleGeneralCardHandlerCommission();
    },
    // 获取通用次卡下经手人提佣方案
    getSaleGeneralCardHanderCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        GeneralCardID: that.GeneralCardID,
      };
      API.getSaleGeneralCardHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡 --  通用次卡下经手人提佣方案
    PackageSaleGeneralCardHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        GeneralCardID: that.GeneralCardID,
      };
      API.getPackageSaleGeneralCardHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 提交通用次卡提成设置
    submitGeneralCardClick() {
      var that = this;
      that.generalCardList = Enumerable.from(that.generalCardList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.updateSaleGeneralCardCommission();
    },
    // 保存通用次卡销售提成方案
    updateSaleGeneralCardCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        GeneralCard: that.generalCardList,
        CategoryID: that.CategoryID,
      };
      API.updateSaleGeneralCardCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogGeneralCard = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 经手人提成
    handerCommission: function (type, row) {
      var that = this;
      that.handleActiveName = "0"
      if (!row.isEntity) {
        that.CategoryID = row.CategoryID;
      }
      that.handlerType = type;
      that.categoryName = row.categoryName;
      that.whichHandlerDialog = "0";
      if (row.isEntity) {
        that.getAllSaleGeneralCardSchemeHanderCommission();
        that.PackageSaleGeneralCardSchemeHandlerCommission();
      } else {
        that.getSaleGeneralCardCategoryHandlerCommission();
        that.PackageSaleGeneralCardCategoryHandlerCommission();
      }
      that.dialogHandler = true;
    },
    // 获取所有通用次卡经手人提佣方案
    getAllSaleGeneralCardSchemeHanderCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getAllSaleGeneralCardSchemeHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取所有套餐卡 -- 通用次卡经手人提佣方案
    PackageSaleGeneralCardSchemeHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getPackageSaleGeneralCardSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡销售分类经手人提佣方案
    getSaleGeneralCardCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getSaleGeneralCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡 通用次卡销售分类经手人提佣方案
    PackageSaleGeneralCardCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getPackageSaleGeneralCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 通用次卡提成设置
    clickProjectCommission: function (row) {
      var that = this;
      that.CategoryID = row.CategoryID;
      that.categoryName = row.categoryName;
      that.getSaleGeneralCardCommission();
      that.dialogGeneralCard = true;
    },
    // 获取分类下的通用次卡提佣方案
    getSaleGeneralCardCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getSaleGeneralCardCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 提交经手人
    submitHandlerClick() {
      var that = this;
      that.handlerList.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      that.handlerList = Enumerable.from(that.handlerList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .toArray();
      that.handlerList.forEach((val) => {
        val.SaleHandlerID = val.ID;
      });


      that.packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      that.packageHanderCommission = Enumerable.from(that.packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .toArray();
      that.packageHanderCommission.forEach((val) => {
        val.SaleHandlerID = val.ID;
      });

      if (that.whichHandlerDialog == "0") {
        if (that.handlerType == "0") {
          that.updateSaleGeneralCardSchemeHandlerCommission();
        } else {
          that.updateSaleGeneralCardCategoryHandlerCommission();
        }
      } else {
        that.updateSaleGeneralCardHanderCommission();
      }
    },
    // 保存通用次卡销售分类经手人提成方案
    updateSaleGeneralCardCategoryHandlerCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
        GeneralCardHandler: that.handlerList,
        PackageCardGeneralCardHandler: that.packageHanderCommission,
      };
      API.updateSaleGeneralCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存所有通用次卡经手人提成方案
    updateSaleGeneralCardSchemeHandlerCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        GeneralCardHandler: that.handlerList,
        PackageCardGeneralCardHandler: that.packageHanderCommission,
      };
      API.updateSaleGeneralCardSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存通用次卡下经手人提成方案
    updateSaleGeneralCardHanderCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        GeneralCardID: that.GeneralCardID,
        GeneralCardHandler: that.handlerList,
        PackageCardGeneralCardHandler: that.packageHanderCommission,
      };
      API.updateSaleGeneralCardHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 高亮第一级表格
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "info-row";
      }
      return "";
    },
    // 树形数据结构转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    // 约束提成比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.PayRate > 100) {
          row.PayRate = 100;
        }
      } else if (index == 2) {
        if (row.SavingCardRate > 100) {
          row.SavingCardRate = 100;
        }
      } else if (index == 3) {
        if (row.SavingCardLargessRate > 100) {
          row.SavingCardLargessRate = 100;
        }
      }
    },
  },
  mounted() {
    var that = this;
    that.getSaleGeneralCardCommissionScheme();
    that.getEntity();
    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-HanderCommission-SaleGeneralCardCommissionScheme-Delete"
    );
  },
};
</script>

<style lang="scss">
.SaleGeneralCardCommissionScheme{
  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-table .info-row {
    background: #c0c4cc;
  }
  .el-input__inner {
    padding-right: 0;
  }
}

</style>
